import React from 'react'
import { Container } from 'react-bootstrap'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const HeroUnit = ({ children, backgroundImage, className }) => {
    const bgImage = getImage(backgroundImage)

    return (
        <section className={`position-relative ${className}`}>
            <div className='d-flex'>
                <Container className='py-7'>{children}</Container>
                <GatsbyImage
                    className='z-index-n1 position-absolute w-100 h-100'
                    alt=''
                    image={bgImage}
                    role='presentation'
                    placeholder='blurred'
                    loading='eager'
                />
            </div>
        </section>
    )
}

export default HeroUnit
